/* CSS */

.TalkList .cell {
    text-align: left;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    padding: 8px;
}

.TalkList .room-image {
    width: 10vw;
    height: 10vw;
    max-height: 80px;
    max-width: 80px;
    border-radius: 5vw;
    display: inline-block;
    object-fit: cover;
    margin: 6px 20px 6px 6px;
    vertical-align: top;
}

.TalkList .room-name {
    margin: 0;
    color: #000000;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TalkList .room-user-name {
    margin: 0;
    color: #8C8C8C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 0.9rem;
}

.TalkList .room-comment {
    margin: 0;
    color: #8C8C8C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
}

.TalkList .room-nodata {
    margin-top: 64px;
    text-align: center;
    color: #8C8C8C;
}