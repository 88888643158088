/* CSS */

div.UserCell {
    margin-bottom: 16px;
}

div.UserCell div.img {
    display: table-cell;
}

div.UserCell div.text {
    display: table-cell;
    padding: 0 16px;
    margin: 0;
    vertical-align: top;
}

.UserCell img.icon {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    object-fit: cover;
}

.UserCell p {
    color: #737373;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: left;
    padding: 0;
    margin: 0;
    color: gray;
}

.UserCell p.name {
    color: #737373;
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
}
