/* CSS */

.imagePreview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.imagePreview .box {
    width: 25vw;
    height: 25vw;
    font-size: 5vw;
    border-radius: 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.imagePreview .close-button {
    position: absolute;
    left: 15px;
    top: 10px;
}

.imagePreview .close-button .fa {
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke: 1px #fff;
}