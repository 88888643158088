/* CSS */

div.CommonButton {
  text-align: center;

}

.CommonButton button {
    font-size: 1.3rem;
    color: #999999;
    border: solid 2px #b8b8b8;
    border-radius: 10px;
    padding: 10px 0px;
    background-color: white;
    margin-bottom: 30px;
    width: 100%;
}
