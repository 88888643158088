/* CSS */

div.CommunityRegist {
    padding-bottom: 1rem;
}

.CommunityRegist .img_area {
    position:  relative;
    width: 30vw;
    height: 30vw;
    margin: 32px auto;
}

.CommunityRegist .image .add {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6vw;
    height: 6vw;
}

.CommunityRegist .logo {
    width: 30vw;
    height: 30vw;
    /*border-radius: 50%;*/
    object-fit: cover;
    /*border: 1px solid #cccccc;*/
}

.CommunityRegist .user-image {
    display: none;
}

.CommunityRegist .image label {
    margin: 0.2rem 0 0 0;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    font-weight: 600;
    color: #9B9B9B;
    font-size: 0.8rem;
}

.CommunityRegist .detail label {
    margin: 0.2rem 0 0 0;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    font-weight: 600;
    color: #9B9B9B;
    font-size: 0.8rem;
}

.CommunityRegist input.signup-input , .CommunityRegist input.react-tagsinput-input {
    margin-top: 8px;
}

.CommunityRegist select.signup-input {
    margin-top: 8px;
}

div.input-tags {
    margin: 8px auto 0 auto;
    padding: 10px;
    width: 90%;
    font-size: 20px;
    border: 0px;
    border-bottom: 2px solid #E4E4E4;
}

.CommunityRegist p.error {
    margin: 0.2rem auto;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    color: red;
}

.CommunityRegist textarea  {
    margin-top: 8px;
    padding: 10px;
    width: 90%;
    font-size: 20px;
    border: 0px;
    border-bottom: 2px solid #E4E4E4;
}

.CommunityRegist .community_logo img {
    margin: 64px auto 32px auto;
    width: 80%;
}