/* CSS */

.Mypage .user-image {
    height: 20vw;
    width: 20vw;
    max-height: 120px;
    max-width: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 64px;
    margin-bottom: 16px;
    border: 1px solid #cccccc;
}

.Mypage .user-name {
    font-weight: 600;
}

.Mypage ul {
    margin-top: 64px;
    padding: 0;
    width: 100%;
    list-style-type: none;
}

.Mypage li {
    text-align: left;
    padding: 16px 32px;
    border-bottom: 1px solid #F0F0F0;
    font-weight: 600;
}