/* CSS */

div.Dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: 500ms;
}

.Dialog div.modal-body {
    width: 80vw;
    max-width: 500px;
    white-space: pre-wrap;
    word-wrap:break-word
}

.Dialog div.modal-header h5 {
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
}

.Dialog div.modal-footer button {
    margin: 0 auto;
    text-align: center;
    font-weight: 300;

}