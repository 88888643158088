div.StoreDetailProperties {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  white-space: pre-wrap;
}

.StoreDetailProperties .title {
  margin-top: 0;
  color: #4A4A4A;
  font-size: 1.2rem;
  font-weight: bold;
}

.StoreDetailProperties .desc {
  margin-top: 18px;
  color: #4A4A4A;
  font-size: 1rem;
}

.StoreDetailProperties .desc.short {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px;
}

.StoreDetailProperties .text {
  margin-top: 18px;
  color: #4A4A4A;
  font-size: 1rem;
}

.StoreDetailProperties .next {
  color: #F9CD16;
  font-weight: 600;
  text-align: right;
}

.StoreDetailProperties .tag {
  border: 1px solid #F9CD16;
  color: #F9CD16;
  padding: 2px 5px;
  margin: 3px;
}

.StoreDetailProperties input {
  background-color: #F9CD16;
  color: #ffffff;
  border-radius: 6px;
  padding: 8px 16px;
  float: right;
  width: 100%;
}