/* CSS */

.CongregateHelp {
    text-align: left;
}

.CongregateHelp .help h1 {
    font-size: 1.2rem;
    color: black;
    font-weight: 600;
    margin: 24px 0 16px 0;
}

.CongregateHelp .help p {
    font-size: 1rem;
    color: #737373;
    padding-left: 26px;
    white-space: pre-wrap;
}

.CongregateHelp .help input {
    background-color: #F9CD16;
    color: #ffffff;
    font-weight: bold;
    border-radius: 6px;
    padding: 16px 0;
    margin: 64px auto 16px auto;
    width: 80%;
}