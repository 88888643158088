/* CSS */

.CreateSelectType {
}

.contents {
    margin: 1.3rem;
    width: 100%;
}

.section {
    font-size: 1.0rem;
    font-weight: bold;
    text-align: left;
    color: #4A4A4A;
}

.cell-disable {
    color: #E5E8EC!important;
}

.type-cell {
    display: flex;
    align-content: center;
    align-items: center;
    color: #4A4A4A;
    margin-top: 1.3rem;
}

.cell-image {
    height: 15vw;
    width: 15vw;
    max-height: 80px;
    max-width: 80px;
    object-fit: cover;
    border-radius: 1.5rem;
}

.cell-right {
    flex: 1;
    margin-left: 1rem;
}

.cell-right-text {
    text-align: left;
    font-size: 0.9rem;
}

.cell-right-subtext {
    text-align: left;
    font-size: 0.8rem;
}

.cell-arrow {
    font-size: 1.5rem;
    color: rgb(178, 178, 178);
    margin-left: 1rem;
}