/* CSS */

.CommonSwiperCell .title {
    color: #737373;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 2px;
}

.CommonSwiperCell .location {
    font-size: 0.8rem;
    margin: 8px 2px 2px 2px;
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CommonSwiperCell .location img {
    height: 16px;
    width: auto;
    margin-right: 6px;
    margin-left: 2px;
    margin-bottom: 4px;
    object-fit: cover;
}

.CommonSwiperCell .text {
    font-size: 0.8rem;
    margin: 2px 2px 2px 2px;
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.CommonSwiperCell .box.active {
    border: 1px solid #F5A623;
    background-color: #FFF9F2;
}

.CommonSwiperCell .date {
    color: #737373;
    text-align: left;
    margin-bottom: 4px;
}

.CommonSwiperCell .comment-box {
    text-align: left;
    background-color: #f0f0f0;
    font-size: 0.8rem;
    color: black;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 16px;
}