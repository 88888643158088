/* CSS */


.CommonUserCell {

}

.CommonUserCell .box {
    display: table;
    width: 100%;
    margin: 5px 0;
    padding: 1rem;
    text-align: left;
}

.CommonUserCell img.user-image {
    display: table-cell;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: top;
    border: 1px solid #cccccc;
}

.CommonUserCell img.detail-image {
    display: table-cell;
    width: 60px;
    height: 60px;
    border-radius: 3px;
    object-fit: cover;
    vertical-align: top;
    border: 1px solid #cccccc;
}

.CommonUserCell div.user-detail {
    display: table-cell;
    vertical-align: middle;
    padding: 0 16px;
    width: 100%;
}

.CommonUserCell .title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 6px 20px 6px 6px;
}

.CommonUserCell .text {
    font-size: 1rem;
    margin: 6px;
}

.CommonUserCell .desc {
    font-size: 0.8rem;
    color: gray;
    margin: 0 20px 6px 6px;
    white-space: pre-wrap;
}

.CommonUserCell .box.active {
    border: 1px solid #F5A623;
    background-color: #FFF9F2;
}