/* CSS */

.Create {

}

.Create .user-info-cell {
    margin: 3vw 5vw;
    font-size: 0.9rem;
}

.Create .user-info-cell-right {
    margin-top: 1vw;
}

.Create .user-info-light-description {
    color: #F5A623;
}

.star-cell {
    padding: 3vw;
}

.star-info {
    font-size: 0.5rem;
    color: gray;
}

.form-section {
    background-color: #EEEEEE;
    text-align: left;
    width: 100%;
    padding: 0.5rem 0 0.5rem 1rem;
    font-size: 0.8rem;
    color: #4A4A4A;
}

.form-margin {
    background-color: #EEEEEE;
    width: 100%;
    height: 0.8rem;
}