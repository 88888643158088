/* CSS */

nav.Menu {
    width: 80vw;
    padding: 44px 0;
    text-align: left;
}

nav.Menu a {
    color: #8C8C8C;
    font-weight: 600;
}

nav.Menu .user a {
    display: block;
    margin-bottom: 1rem;
}

nav.Menu .user-image {
    display: inline-block;
    height: 14vw;
    width: 14vw;
    max-height: 120px;
    max-width: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0;
    border: 1px solid #cccccc;
}

nav.Menu .user-name {
    display: inline-block;
    font-weight: 600;
    padding-left: 16px;
    color: #000000;
}

nav.Menu .community {
    text-align: left;
    padding: 0;
    margin: 0.5rem 0;
}

nav.Menu .community .community-image {
    display:table-cell;
    width: 1px;
    white-space:nowrap;
}

nav.Menu .community img {
    width: 14vw;
    height: 14vw;
    max-height: 80px;
    max-width: 80px;
    object-fit: cover;
    border-radius: 10%;
}

nav.Menu .add img {
    width: 14vw;
    height: 14vw;
    max-height: 80px;
    max-width: 80px;
    object-fit: cover;
    border-radius: 0;
}

nav.Menu .community a {
    display: table;
    max-height: 80px;
    width: 100%;
}

nav.Menu .community div.community-box {
    display:table-cell;
    padding: 0 0 0 1rem;
    margin: 0;
}

nav.Menu .community .community-name {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
    word-wrap: break-word;
}

nav.Menu .community .community-count {
    margin: 0;
    padding: 0.2rem 0 0 0;
    font-size: 0.7rem;
}

nav.Menu .common-title {
    border-top: 1px solid #F0F0F0;
    color: #8C8C8C;
    font-weight: 600;
    margin-top: 1rem;
    padding: 2rem 0 0 0;
}

nav.Menu .common {
    text-align: left;
    padding: 0;
    margin: 0.5rem 0;
}

nav.Menu .common a {
    display: table;
    height: 12vw;
    max-height: 80px;
    width: 100%;
}

nav.Menu .common .common-image {
    display:table-cell;
    width: 12vw;
    height: 12vw;
    max-height: 80px;
    max-width: 80px;
    padding: 0.5rem;
    object-fit: cover;
    white-space:nowrap;
}

nav.Menu .common img {
    width: 12vw;
    height: 12vw;
    max-height: 80px;
    max-width: 80px;
    padding: 0.5rem;
    object-fit: cover;
    border-radius: 10%;
}

nav.Menu .common div.common-box {
    display:table-cell;
    padding: 0 0 0 1rem;
}

nav.Menu .common .title {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
    word-wrap: break-word;
}

nav.Menu .arrow-box {
    position: absolute;
    top: 9px;
    right: 0;
    display:table-cell;
    vertical-align: top;
    text-align: right;
}

nav.Menu img.arrow {
    margin: 16px 8px 0 16px;
    height: 1rem;
    width: auto;
    display: inline;
}

nav.Menu .open img.arrow {
    transform: rotate(90deg);
}

nav.Menu div.select-area {
    background-color: #f0f0f0;
}