/* CSS */

.PurchaseDetailCell {

}

.PurchaseDetailCell {
    padding: 1rem 0;
    padding: 1rem 0;
    border-bottom: 1px solid #F0F0F0;
}

.PurchaseDetailCell .product {
    text-align: left;
}

.PurchaseDetailCell .product a {
    display: table;
    height: 30vw;
    width: 100%;
}

.PurchaseDetailCell .product .product-image {
    display: table-cell;
    width: 1px;
    height: 12vw;
    padding: 0;
    object-fit: cover;
    white-space: nowrap;
}

.PurchaseDetailCell .product img {
    width: 12vw;
    height: 12vw;
    padding: 0.5rem;
    object-fit: cover;
    border-radius: 50%;
}

.PurchaseDetailCell .product div.product-box {
    display: table-cell;
    padding: 0 0 0 1rem;
    vertical-align: middle;
}

.PurchaseDetailCell .product .title {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    word-wrap: break-word;
}

.PurchaseDetailCell .product .title .orange {
    color: #F5A623;
}

.PurchaseDetailCell .product .date {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    color: #4A4A4A;
}

.PurchaseDetailCell .product .desc {
    padding: 8px 0 0 32px;
    font-size: 0.9rem;
    color: #4A4A4A;
}

.PurchaseDetailCell .product .sub-title {
    padding: 0 0 0 32px;
    color: #4A4A4A;
}

.PurchaseDetailCell .buttons {
    padding: 8px 0 0 48px;
    text-align: left;
}

.PurchaseDetailCell .btn-nomal {
    background-color: #E4E4E4;
    color: #ffffff;
    padding: 6px 16px;
    margin: 6px;
    border-radius: 6px;
    width: auto;
    height: auto;
}

.PurchaseDetailCell .btn-orange {
    background-color: #F5A623;
    color: #ffffff;
    padding: 6px 16px;
    margin: 6px;
    border-radius: 6px;
    width: auto;
    height: auto;
}