div.Remind {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 88px;
}

.Remind .logo {
    margin-top: 64px;
    width: 90%;
    height: auto;
}

.Remind .footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    max-width: 768px;
    height: 64px;
    border-top: 2px solid #E4E4E4;
    background-color: #FFFFFF;
    padding: 16px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #F9CD16;
}

.Remind img.or {
    margin: 32px auto 16px auto;
    width: 90%;
    max-width: 500px;
    height: auto;
}

.Remind a.reminder {
    display: block;
    color: #9B9B9B;
    font-size: 0.8rem;
    margin-top: 16px;
}