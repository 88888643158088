/* CSS */

div.CommunityEventList {
    text-align: left;
}

div.CommunityEventList .no-data {
    height: 120px;
    padding-top: 50px;
    color: #66656B;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}