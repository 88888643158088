/* CSS */

.FormImageList {

}

.input-image-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #EEEEEE;
}

.input-image {
    position: relative;
    width: 100%;
    height: 21vw;
    margin-left: 2vw; 
}

.input-image:first-child {
    margin-left: 1rem;
}

.input-image:last-child {
    margin-right: 1rem;
}

.input-image-content {
    width: 100%;
    height: 100%;
    border-radius: 2vw;
    overflow: hidden;
    background-color: white;
}

.input-image-delete{
    position: absolute;
    width: 6vw;
    height: 6vw;
    right: -1.5vw;
    top: -1.5vw;
    border-radius: 3vw;
    background-color: gray;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    font-family: Arial, sans-serif;
    z-index: 1;
}

.input-image-index {
    position: absolute;
    background-color: #D3D3D3;
    color: white;
    top: 2vw;
    left: 2vw;
    height: 6vw;
    width: 6vw;
    max-height: 45px;
    max-width: 45px;
    border-radius: 50%;
    text-align: center;
    line-height: 6.2vw;
    font-size: 0.8rem;
    font-weight: bold;
    /* z-index: 1; */
}

.input-image-placeholder {
    position: absolute;
    font-size: 0.7rem;
    text-align: center;
    color: #4A4A4A;

    top: 57%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    padding: 20px;
}

.input-image-logo {
    width: 7vw;
}

.input-image-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}