/* CSS */

.Create {

}

*:focus {
    outline: none;
}

input[type="file"] {
    display: none;
 }

 input{
    font-size : 16px;
    border : none;
    -webkit-border-radius : 0;
    -webkit-appearance : none;
    -webkit-tap-highlight-color : rgba(0,0,0,0);
    margin: 0;
    padding: 0;
    border-width: 0;
}

 select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
}

 input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

form {
    width: 100%;
}

.form-section {
    background-color: #EEEEEE;
    text-align: left;
    width: 100%;
    padding: 1.5rem 0 0.5rem 1rem;
    font-size: 0.8rem;
    color: #4A4A4A;
}

.input-wrapper {
    width: 100%;
}

.form-input-text {
    padding: 0.5rem 1rem;
    border-width: 0;
    font-size: 1rem;
    width: 100%;
    background-color: white;
    color: #4A4A4A;
}

.form-input-label {
    float: left;
    padding: 0.5rem 1rem;
}

.form-input-text-wrapper-with-unit {
    overflow: hidden;
    width: 100%;
    display: flex;
}


.form-input-text-with-label {
    float: right;
    text-align: right;
    padding: 0.5rem 1rem;
    background-color: white;
    color: #4A4A4A;
}

.form-input-text-with-unit {
    display: inline-block;
    text-align: right;
    padding: 0.5rem 1rem;
    padding-right: 0;
    background-color: white;
    color: #4A4A4A;
    flex: 1;
}

.form-input-text-unit {
    display: inline-block;
    text-align: right;
    padding: 0.5rem 1rem;
    padding-left: 0;
    margin-left: 0.3rem;
    background-color: white;
    color: #4A4A4A;
}

.large.form-input-text {
    height: 19vh;
}

.form-input-date {
    padding: 0.5rem 1rem;
    position: relative;
}

.form-input-date-item-wrapper {
    display: flex;
    padding: 0.5rem 0;
}

.form-input-date-item {
    flex: 1;
    border-width: 0;
    font-size: 1rem;
    background-color: white;
    color: #4A4A4A;
}

.form-input-date-item-label {
    text-align: left;
    color: #4a4a4a;
}

.form-input-date-item-delete {
    position: absolute;
    top: 0.1rem;
    right: 1rem;
    font-size: 1.4rem;
    color: #D3D3D3;
}

.form-border {
    width: 100%;
    height: 1px;
    background-color: #EEEEEE;
}

.select-option-disable {
    color: #4a4a4a;
}

.react-select-container {
    width: 100%;
}

.react-select__control, .react-select__control--is-focused {
    border-width: 0!important;
    border-color: transparent!important;
    box-shadow: none!important;
}

.react-select__indicator-separator {
    display: none;
}

.react-select__value-container {
    /* 2pxはカーソルインディケーターのため */
    padding: calc(0.5rem - 4px) calc(1rem - 2px)!important;
}

.react-select__menu {
    z-index: 2;
}

.input-image-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #EEEEEE;
}

.input-image {
    position: relative;
    width: 21vw;
    height: 21vw;
    padding: 0;
}

.input-image-content {
    width: 100%;
    height: 100%;
    border-radius: 2vw;
    overflow: hidden;
    background-color: white;
}

.input-image-delete{
    position: absolute;
    width: 6vw;
    height: 6vw;
    right: -1.5vw;
    top: -1.5vw;
    border-radius: 3vw;
    background-color: gray;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    font-family: Arial, sans-serif;
    z-index: 1;
}

.input-image-index {
    position: absolute;
    background-color: #D3D3D3;
    color: white;
    top: 10%;
    left: 10%;
    height: 6vw;
    width: 6vw;
    max-height: 45px;
    max-width: 45px;
    border-radius: 50%;
    text-align: center;
    line-height: 6.2vw;
    font-size: 0.8rem;
    font-weight: bold;
    vertical-align: middle;
    /* z-index: 1; */
}

.input-image-placeholder {
    position: absolute;
    top: 33%;
    left: 34%;
    font-size: 0.7rem;
    text-align: center;
    color: #4A4A4A;
}

.input-image-logo {
    width: 7vw;
}

.input-image-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.multi-select-list {
}

.multi-select-label {
    display: inline-block;
    width: 40vw;
    color: #4A4A4A;
}

input.react-multi-select-input {
    width: 20vw;
    text-align: right;
    padding: 0.5rem 1rem;
}

.react-multi-select-container {
    display: inline-block;
    width: 20vw;
    min-width: 4.5rem;
}

.react-multi-select-container .react-select__value-container{
    padding: 0!important;
}

.date-label{
    display: inline-block;
    width: 100vw;
    justify-content: center;
    justify-items: center;
    vertical-align: center;
}

.date-add-cell {
    padding: 0.5rem 1rem;
    text-align: left;
    color: #4A4A4A;
}

.date-add {
    height: 1.5rem;
    width: auto;
    float: right;
    margin: auto 0;
}

.input-tags {
    width: 100%;
    text-align: left;
}

.input-tags > span {
    display: flex;
    flex-wrap: wrap;
}

.react-tagsinput-tag {
    padding: 0.2rem 0.5rem;
    border: solid #F9CD16 1px;
    border-radius: 1rem;
    color: #FFFFFF;
    margin-right: 0.4rem;
    margin-bottom: 0.5rem;
    background-color: #F9CD16;
}

.react-tagsinput-input {
    color: #4a4a4a;
    flex: 1;
}

.form-label-cell {
    display: flex;
    padding: 0.5rem 1rem;
    color: #4a4a4a;
}

.form-label-cell-label {
    flex: 1;
    text-align: left;
}

.form-label-cell-value {

}

.submit-button-wrapper {
    background-color: #EEEEEE;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.submit-button {
    background-color: #F9CD16;
    width: 80vw;
    max-width: 614px;
    margin: 0 auto;
    border-radius: 2vw;
    color: white;
    font-weight: bold;
    padding: 1rem;
}

.submit-button-image {
    height: 1.4rem;
    margin-right: 0.5rem;
}