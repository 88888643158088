/* CSS */

div.StoreDetail {
    padding-bottom: 79px;
    /*border: 1px dotted #ccc;*/
}

.StoreDetail div.detail {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: left;
    white-space: pre-wrap;
}

.StoreDetail div.border-bottom {
    border-bottom: solid #D8D8D8 1px;
}

.StoreDetail .detail .title {
    margin-top: 0;
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: bold;
}

.StoreDetail .detail .desc {
    margin-top: 18px;
    color: #4A4A4A;
    font-size: 1rem;
}

.StoreDetail .detail .desc.short {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 70px;
}

.StoreDetail .detail .text {
    margin-top: 18px;
    color: #4A4A4A;
    font-size: 1rem;
}

.StoreDetail .detail .next {
    color: #F9CD16;
    font-weight: 600;
    text-align: right;
}

.StoreDetail .detail .tag {
    border: 1px solid #F9CD16;
    color: #F9CD16;
    padding: 2px 5px;
    margin: 3px;
}

.StoreDetail .help p {
    font-weight: 400;
    color: #9B9B9B;
    padding-top: 8px;
}

.StoreDetail .detail input {
    background-color: #F9CD16;
    color: #ffffff;
    border-radius: 6px;
    padding: 8px 16px;
    float: right;
    width: 100%;
}

.StoreDetail .border {
}

.StoreDetail div.button {
    text-align: left;
    width: 100%;
    max-width: 768px;
    position: fixed;
    bottom: 0;
    height: 80px;
    background-color: #FFFFFF;
    border-top: solid #D8D8D8 1px;
    padding-top: 16px;
}

.StoreDetail .button p.desc {
    font-size: 0.6rem;
    color: #F5A623;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.StoreDetail .button p.price {
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.StoreDetail .button input {
    background-color: #F9CD16;
    color: #ffffff;
    border-radius: 6px;
    padding: 4px 16px;
    float: right;
    width: 100%;
    height: 50px;
}

.StoreDetail .button input.disable {
    background-color: #9B9B9B;
}

.StoreDetail div.date {
    padding-top: 16px;
    padding-bottom: 32px;
}

.StoreDetail .date .react-calendar {
    width: 100%;
    padding: 0;
    border: 0;
}

.StoreDetail .date .react-calendar .react-calendar__tile {
    border-radius: 5px;
    background-color: #fdeacc;
}

.StoreDetail .date .react-calendar .react-calendar__tile:disabled {
    background-color: #ffffff;
}

.StoreDetail .date .react-calendar .react-calendar__tile--active {
    background-color: #F5A623;

}

.StoreDetail .react-calendar button:disabled {
    color: #757575;
}

.StoreDetail .react-calendar button:disabled.react-calendar__month-view__days__day--neighboringMonth {
    color: #eeeeee;
}

.StoreDetail .map {
    height: 230px;
    width: 100%;
    margin-top: 16px;
    border-radius: 8px;
}

.StoreDetail .meta-box {
    text-align: left;
}

.StoreDetail .meta-box .title {
    margin-top: 18px;
    margin-bottom: 12px;
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: bold;
}

.StoreDetail .cell {
    text-align: left;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    padding: 8px;
}

.StoreDetail .selected-user-image-list {
    text-align: left;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    height: 100px;
    margin-bottom: 16px;
}

.StoreDetail div.user-box {
    display: inline-block;
    margin: 0 8px 0 0px;
    height: 100px;
    width: 60px;
}

.StoreDetail .user-box img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #cccccc;
}

.StoreDetail .user-box p {
    font-size: 0.6px;
    text-align: center;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.StoreDetail .detail-box {
    text-align: left;
    padding: 24px 0 0 0;
    background-color: #EEEEEE;
}

.StoreDetail .detail-box img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.StoreDetail .detail-box .title {
    margin: 0;
    padding: 3px;
    font-size: 1.2rem;
    font-weight: bold;
}

.StoreDetail .detail-box .desc {
    margin: 0;
    padding: 3px;
    font-size: 0.8rem;
    color: gray;
}

.StoreDetail .form-box {
    text-align: left;
    background-color: #EEEEEE;
    padding: 0;
}

.StoreDetail .form-box .title {
    margin: 16px 0 6px 0;
    padding: 0 15px;
    font-size: 0.8rem;
    color: gray;
    border-bottom: 1px #EEEEEE solid;
}

.StoreDetail .input-wrapper {
    background-color: #ffffff;
    border-bottom: 1px #EEEEEE solid;
}

.StoreDetail input::placeholder, .StoreDetail textarea::placeholder {
    color: #F9CD16;
}

.StoreDetail .btn-box {
    text-align: center;
    background-color: #EEEEEE;
    padding: 64px 0;
}

.StoreDetail .btn-box .button {
    background-color: #F9CD16;
    color: #ffffff;
    font-weight: bold;
    border-radius: 6px;
    padding: 16px 0;
    margin: 0 auto;
    width: 80vw;
}

.StoreDetail .form-input-text-with-label {
    color: #F9CD16;
}

.StoreDetail div.course-image {
    width: 100%;
    height: 20vw;
    max-height: 140px;
}

.StoreDetail .course-image img {
    width: 100%;
    height: 20vw;
    max-height: 140px;
    object-fit: cover;
}

.StoreDetail .course-image p {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    margin: 0;
    padding: 3px 6px;
    font-size: 0.6rem;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    background-color: rgba(249, 205, 22, 0.8);
}

.StoreDetail .icon {
    height: 1rem;
    width: 16px;
    object-fit: contain;
    margin-right: 20px;
}

.StoreDetail .form-box .form-input-text.large {
    height: 20vh;
}
.StoreDetail input.number {
    background-color: white;
    border: 1px solid #9B9B9B;
    color: #9B9B9B;
    border-radius: 20px;
    padding: 8px 16px;
    margin: 0 3px;
}
.StoreDetail input.number.active {
    background-color: #F9CD16;
    border: none;
    color: #ffffff;
}

.StoreDetail select.number_of_people {
    color: #F9CD16;
    margin-top: 0;
    width: 100%;
    text-align: right;
}

.StoreDetail select.number_of_people option {
    text-align: right;
}

.StoreDetail input.signup-input {
    width: 100%;
    margin-top: 0;
}

.StoreDetail .CommonSegmentButton {
    margin-bottom: 20px;
}