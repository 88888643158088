/* CSS */

div.Congregate  {
    /*border: 1px dotted #ccc;*/
    width: 100%;
}

.Congregate Header {
    margin-bottom: 32px;
}

.Congregate .list .title {
    text-align: left;
    padding: 16px 0 8px 0;
    font-size: 1rem;
    font-weight: 600;
    color: #737373;
    margin: 8px 0;
}

.Congregate .list .title img {
    margin: 0 10px 0 0;
    height: 25px;
    width: 25px;
    object-fit: contain;
}

.Congregate .cell {
    width: 260px;
    height: 340px;
    padding: 0 10px;
    margin-bottom: 10px;
}

.Congregate div.image-box {
    position: relative;
    width: 100%;
    height: 180px;
    border-radius: 6px;
}

.Congregate .image-box img.main {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.Congregate .image-box img.status {
    position: absolute;
    top: 0;
    left: 0;
    width: 35%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
}

.Congregate .image-box p {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 3px 6px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #ffffff;
    object-fit: cover;
    text-align: left;
    background-color: rgba(249, 205, 22, 0.8);
    border-radius: 0 0 6px 6px;
}

.Congregate .image-box div {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 25% 0 0 0;
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(30, 30, 30, 0.6);
    border-radius: 6px;
}

.Congregate .meta-box {
    text-align: left;
}

.Congregate .meta-box .benefits {
    margin: 0;
    padding: 3px;
    font-size: 1.2rem;
    font-weight: 400;
    color: #F5A623;
}

.Congregate .meta-box .date {
    margin: 0;
    padding: 3px;
    font-size: 0.8rem;
    color: #F5A623;
}

.Congregate .meta-box .title {
    margin: 3px;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    max-height: 1.6rem;
    overflow: hidden;
}

.Congregate .meta-box .desc {
    margin: 0;
    padding: 3px;
    font-size: 0.8rem;
    color: gray;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.Congregate .btn-box {
    position: absolute;
    bottom: 0;
    padding: 6px 0;
    text-align: left;
    width: 240px;
}

.Congregate .btn-box img {
    width: 35px;
    height: 35px;
    margin: 0 2px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #cccccc;
}

.Congregate .btn-box input {
    background-color: #F9CD16;
    color: #ffffff;
    border-radius: 6px;
    padding: 4px 16px;
    float: right;
}

.Congregate .w100 {
    width: 100%;
}

.Congregate .swiper {
    padding: 0;
    text-align: center;
}

.Congregate div.select-title {
    /*padding-top: 25px;*/
}

.Congregate .select-title p {
    margin: 3px;
    padding: 0;
    font-size: 0.6rem;
    max-height: 3.2rem;
    overflow: hidden;
    text-align: left;
}

.Congregate .tag , .react-datepicker__input-container input {
    height: 50px;
    color: #333333;
    width: 100%;
    text-align: left;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #F0F0F0;
    background-color: white;
}

.Congregate .input-search {
    height: 50px;
    color: #333333;
    width: 100%;
    text-align: left;
    font-weight: bold;
    padding: 0 16px;
    border: none;
    border-bottom: 1px solid #F0F0F0;
    background-color: white;
}

.Congregate .select {
    color: #F9CD16;
    float: right;
}

.Congregate .tip-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    background-color: white;
    opacity: 0.1;
}

div.__react_component_tooltip {
    min-width: 200px;
    text-align: center;
}

.Congregate .user-list-cell {
    display: flex;
    padding: 16px 0;
    border-bottom: 1px #F0F0F0 solid;
}

.Congregate .user-list-cell .title {
    /*width: 200px;*/
    padding-left: 16px;
}

.Congregate .user-list-cell .user-list {
    flex: 1;
    height: 48px;
    overflow-x: hidden;
    white-space:nowrap;
    text-align: left;
    padding: 6px 8px 0px 8px;
}

.Congregate .user-list-cell .arrow {
    /*width: 40px;*/
}

.Congregate .user-list-cell img.icon {
    margin: -4px 8px 0 8px;
    height: 1rem;
    width: auto;
    display: inline;
}
.Congregate .user-list-cell img.arrow {
    margin: 16px 8px 0 8px;
    height: 1rem;
    width: auto;
    display: inline;
}

.Congregate .user-list-cell p.icon {
    display: inline-block;
    vertical-align: top;
}

.Congregate .user-list-cell .sub-title {
    display: inline-block;
    font-size: 1rem;
    color: #4A4A4A;
    margin: 0 8px;
    text-align: left;
}

.user-list img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin: 0 2px;
}


.Congregate .c-box {
    position: absolute;
    bottom: 0;
    padding: 6px 0;
    text-align: left;
    width: 240px;
    display: flex;
}

.Congregate .c-box .box1 {
    width: 40%;
    height: 54px;
    font-size: 0.7rem;
    font-weight: 600;
    margin: 0 2px;
    padding: 3px;
    background-color: #F5A623;
    color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
}

.Congregate .c-box .box2 {
    width: 40%;
    height: 54px;
    font-size: 0.7rem;
    font-weight: 600;
    margin: 0 2px;
    padding: 3px;
    background-color: #FFDA00;
    color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
}

.Congregate .c-box .next {
    width: 20%;
    height: 54px;
    font-size: 0.7rem;
    font-weight: 600;
    margin: 0 2px;
    padding: 3px;
    background-color: #E4E4E4;
    color: #ffffff;
    border-radius: 4px;
}

.Congregate .no-data {
    height: 120px;
    padding-top: 50px;
    color: #66656B;
    font-size: 1rem;
    font-weight: 300;
}

.Congregate .large-button {
    margin: 16px;
    color: gray;
    font-size: 0.8rem;
    border: solid 1px gray;
    border-radius: 5px;
    padding: 10px;
}

.Congregate .create-event-button {
    background-color: #FFDA00;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: fixed;
    bottom: 30px;
    right: 10px;
    color: white;
    font-size: 5px;
    padding-top: 10px;
    font-weight: bold;
}

/* --- イベントコンポーネント --- */
/* みんなが企画した募集中イベント */
.user-event-list {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
}

.user-event-cell {
    width: calc((100vw - 48px) / 2);
    max-width: 180px;
    margin-left: 16px;
    margin-bottom: 16px;
    text-align: left;
}

.user-event-cell .cover {
    position: relative;
    height: 110px;
    overflow: hidden;
    border-radius: 5px;
}

.user-event-cell .cover img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user-event-cell .cover-footer {
    position: absolute;
    bottom: 0;
    height: 25px;
    width: 100%;
    background-color: rgba(249, 205, 22, 0.8);
    color: white;
    font-size: 0.75rem;
    line-height: 25px;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}

.user-event-cell .subtext1 {
    font-size: 0.7rem;
    font-weight: bold;
    color: #F5A623;
    text-align: left;
    margin-top: 0.5rem;
}

.user-event-cell .title {
    font-size: 0.9rem;
    font-weight: bold;
}

.user-event-cell .subtext2 {
    font-size: 0.7rem;
    color: gray;
}

.user-info {
    margin-top: 0.5rem;
    display: flex;
}

.user-info .left-area {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
}

.user-info .left-area img {
    height: 100%;
    width: 100%;
}

.user-info .right-area {
    margin-left: 5px;
}

.user-info .right-area .name {
    color: gray;
    font-size: 0.7rem;
    font-weight: bold;
}

.user-info .right-area .subtext {
    color: gray;
    font-size: 0.2rem;
}

/* 運営公式イベント */
.admin-event-list {
    display: flex;
    max-width: 250px;
    padding: 0;
    flex-wrap: wrap;
}

.admin-event-cell {
    width: 50vw;
    padding-left: 16px;
    /* margin-bottom: 16px; */
    text-align: left;
}

.admin-event-cell .cover {
    position: relative;
    height: 110px;
    overflow: hidden;
    border-radius: 5px;
}

.admin-event-cell .cover img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.admin-event-cell .cover-footer {
    position: absolute;
    bottom: 0;
    height: 25px;
    width: 100%;
    background-color: rgba(249, 205, 22, 0.8);
    color: white;
    font-size: 0.75rem;
    line-height: 25px;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}

.admin-event-cell .subtext1 {
    font-size: 0.7rem;
    font-weight: bold;
    color: #F5A623;
    text-align: left;
    margin-top: 0.5rem;
}

.admin-event-cell .title {
    font-size: 0.9rem;
    font-weight: bold;
}

.admin-info {
    margin-top: 0.5rem;
    display: flex;
}

.admin-info .left-area {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
}

.admin-info .left-area img {
    height: 100%;
    width: 100%;
}

.admin-info .right-area {
    margin-left: 5px;
}

.admin-info .right-area .name {
    color: gray;
    font-size: 0.7rem;
    font-weight: bold;
}

.admin-info .right-area .subtext {
    color: gray;
    font-size: 0.2rem;
}

/* ガイド */
.Congregate .guide {
    margin: 20px 16px 0px 16px;
    background-color: #f0f0f0;
    border-radius: 10px;
    width: 100vw;
    padding: 10px 20px;
}

.Congregate .guide-title {
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    color: #ffc107;
}

.Congregate .guide-image-wrapper {
    margin-bottom: 0.8rem;
}

.Congregate .guide-image-wrapper img{
    max-height: 60px;
}


.Congregate .guide-text {
    font-size: 0.7rem;
}