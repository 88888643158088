/* CSS */

.BenefitDetail {
    padding-bottom: 80px;
}

.BenefitDetail .main {
    text-align: left;
}

.BenefitDetail .main .title {
    margin-top: 16px;
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: 400;
}

.BenefitDetail .main .desc {
    margin-top: 18px;
    color: #4A4A4A;
    font-size: 1rem;
}

.BenefitDetail .price-box {
    text-align: left;
    padding: 32px;
    border-bottom: 1px solid #D8D8D8;
}

.BenefitDetail .price-box .title {
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: bold;
}

.BenefitDetail div.price {
    margin: 16px 0 32px 0;
    border: 1px solid #979797;
    padding: 16px 32px;
    text-align: right;
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: 400;
}

.BenefitDetail div.price input {
    width: 90%;
    text-align: right;
    font-size: 1.4rem;
}

.BenefitDetail div.price .right {
}

.BenefitDetail .price-box .meta {
    margin: 8px 0;
    color: #4A4A4A;
    font-size: 1.0rem;
    font-weight: 400;
}

.BenefitDetail img.icon, .CongregateCreate .detail-box img.icon {
    height: 1rem;
    width: 16px;
    object-fit: contain;
    margin-right: 8px;
}

.BenefitDetail .members-list {
    text-align: left;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    height: 90px;
    margin-bottom: 16px;
}

.BenefitDetail div.user-box {
    display: inline-block;
    margin: 5px 8px;
    height: 80px;
    width: 60px;
}

.BenefitDetail .user-box img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #cccccc;
}

.BenefitDetail .user-box p {
    font-size: 0.6rem;
    text-align: center;
    margin: 0;
    width: 100%;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BenefitDetail p.description {
    color: #9B9B9B;
    white-space: pre-line;
}

.BenefitDetail div.button {
    text-align: left;
    width: 100%;
    max-width: 768px;
    position: fixed;
    bottom: 0;
    height: 80px;
    background-color: #FFFFFF;
    border-top: solid #D8D8D8 1px;
    padding-top: 8px;
}

.BenefitDetail input.btn {
    width: 100%;
    background-color: #F1C400;
    color: #ffffff;
    font-weight: 600;
    margin: 0;
}

.BenefitDetail input.btn.reject {
    background-color: #D3D3D3;
}

.BenefitDetail textarea {
    border: 1px solid #D3D3D3;
    height: 60vh;
}