/* CSS */

div.CommunityMember {
    padding-bottom: 1rem;
}

.CommunityMember .img_area {
    position:  relative;
    width: 20vw;
    height: 20vw;
    margin: 32px auto;
}

.CommunityMember .image .add {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6vw;
    height: 6vw;
}

.CommunityMember .logo {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #cccccc;
}

.CommunityMember .user-image {
    display: none;
}

.CommunityMember .image label {
    width: 100%;
    text-align: center;
}

.CommunityMember .detail label {
    margin: 0.2rem 0 0 0;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    font-weight: 600;
    color: #9B9B9B;
    font-size: 0.8rem;
}

.CommunityMember input.signup-input {
    margin-top: 8px;
}

.CommunityMember select.signup-input {
    margin-top: 8px;
}

.CommunityMember p.error {
    margin: 0.2rem auto;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    color: red;
}

.CommunityMember textarea  {
    margin-top: 8px;
    padding: 10px;
    width: 90%;
    font-size: 20px;
    border: 0px;
    border-bottom: 2px solid #E4E4E4;
}