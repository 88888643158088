/* CSS */

.CommonImageSwiper img {
    width: 100%;
    height: 70vw;
    max-height: 360px;
    object-fit: cover;
}

div.CommonImageSwiper {
    position: relative;
    margin: 0;
    padding: 0;
    /* background-color: gray; */
}

.CommonImageSwiper .box {
    position: absolute;
    bottom: 0;
    z-index: 999;
    padding: 20px 21px 16px 21px;
    text-align: left;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8));
}

.CommonImageSwiper .title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    max-height: 110px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CommonImageSwiper .desc {
    color: #FFFFFF;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CommonImageSwiper .main-image-page-control {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1vh auto;
}

.CommonImageSwiper .page-control-item {
    flex: 1;
    margin: 1vw;
    background-color: rgba(153, 153, 153, 0.438);
    height: 0.13rem;
    max-width: 17vw;
}

.CommonImageSwiper .active.page-control-item {
    background-color: white;
}

.CommonImageSwiper .left-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 16px 0px 0px 16px;
    text-align: left;
    color: white;
    font-size: 1.5rem;
    width: 20vw;
}

.CommonImageSwiper .right-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    padding: 16px 16px 0px 0px;
    text-align: right;
    color: white;
    font-size: 1.5rem;
    width: 20vw;
}

.CommonImageSwiper .btn-help {
    float: right;
    width: auto;
    height: auto;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 2px 0px 0 8px;
    padding: 8px;
}