/* CSS */

div.CongregateDetail {
    text-align: left;
}

.CongregateDetail .border {
    margin-top: 6px;
    height: 1px;
    border-bottom: solid #D8D8D8 1px;
}

.CongregateDetail .margin {
    margin-top: 20px;
    height: 1px;
}

.CongregateDetail div.detail {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    white-space: pre-wrap;
}

.CongregateDetail .detail .next {
    color: #F9CD16;
    font-weight: 600;
    text-align: right;
}

.CongregateDetail .detail .title {
    margin: 0;
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: bold;
}

.CongregateDetail .detail .CommonIconTitle {
    margin-bottom: 0rem;
    font-size: 0.9rem;
    line-height: 1.8rem;
}

.CongregateDetail .detail .CommonIconTitle .icon {
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

.CongregateDetail .detail .CommonIconTitle .btn-map {
    height: 30px;
    width: 70px;
    margin: -1px 8px 2px 8px;
}


.CongregateDetail .desc {
    margin-top: 0px;
    color: #737373;
    font-size: 0.9rem;
    white-space: pre-wrap;
}

.CongregateDetail .detail .desc.short {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 70px;
}

.CongregateDetail .members-list {
    text-align: left;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    height: 80px;
    margin-bottom: 16px;
}

.CongregateDetail div.user-box {
    display: inline-block;
    margin: 0 8px 0 0px;
    height: 80px;
    width: 60px;
}

.CongregateDetail .user-box img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #cccccc;
}

.CongregateDetail .user-box p {
    font-size: 0.6rem;
    text-align: center;
    margin: 0;
    width: 100%;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CongregateDetail .meta-box .title {
    margin-top: 16px;
    margin-bottom: 16px;
    color: #4A4A4A;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
}

.CongregateDetail input.signup-input {
    width: 100%;
    margin-top: 0;
}

.CongregateDetail img.icon, .CongregateCreate .detail-box img.icon {
    height: 1rem;
    width: 16px;
    object-fit: contain;
    margin-right: 8px;
}

.CongregateDetail .content {
    padding-bottom: 16px;
}

.CongregateDetail .invite {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.CongregateDetail .invite-image {
    display: table-cell;
    height: 16vw;
    width: 16vw;
    max-height: 90px;
    max-width: 90px;
    margin-right: 16px;
    object-fit: cover;
    border-radius: 50%;
}

.CongregateDetail .invite-icon {
    display: table-cell;
    padding-top: 0px;
}

.CongregateDetail .community-name {
    margin: 0;
    font-size: 1.1rem;
    text-align: left;
    color: #4A4A4A;
}

.CongregateDetail p.community-link {
    margin: 0.2rem 0 0.5rem 0;
    font-size: 0.8rem;
    text-align: left;
    color: #9B9B9B;
    height: auto;
}

.CongregateDetail .qr {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CongregateDetail .title-box {
    margin-top: 10px;
}

.CongregateDetail .title-box .date {
    color: #D34C44;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
}
.CongregateDetail .title-box .title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
}
.CongregateDetail .title-box .spot {
    color: #6D7278;
    font-size: 0.8rem;
    font-weight: 300;
    margin-bottom: 0.2rem;
}

.CongregateDetail .CommonUserCell {
    padding: 0;
}