/* CSS */

div.CommonCell.cell {
    height: auto;
    padding: 0;
}

.CommonCell .box {
    display: table;
    width: 100%;
    margin: 0;
    padding: 1rem 0px;
    text-align: left;
}

.CommonCell img.image {
    display: table-cell;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    object-fit: cover;
    vertical-align: top;
    border: 1px solid #cccccc;
    margin: 0;
    margin-top: 4px;
    margin-left: 16px;
}

.CommonCell div.detail {
    display: table-cell;
    vertical-align: top;
    width: 100%;
}

.CommonCell p {
    width: 90%;
    margin: 2px;
    padding: 0 8px;
    text-justify: inter-ideograph;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.CommonCell .title {
    color: #737373;
    font-size: 1.3rem;
    font-weight: bold;
}

.CommonCell .location {
    font-size: 0.9rem;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CommonCell .location img {
    height: 16px;
    width: auto;
    margin-right: 6px;
}

.CommonCell .sub_title {
    color: red;
    font-size: 0.9rem;
    margin: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CommonCell .desc {
    font-size: 0.8rem;
    color: gray;
    margin: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CommonCell .text {
    font-size: 0.8rem;
    margin: 6px 2px 2px 2px;
    max-height: 3.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CommonCell .box.active {
    border: 1px solid #F5A623;
    background-color: #FFF9F2;
}

.CommonCell .community_name {
    font-size: 1.1rem;
    color: gray;
    margin: 6px 2px 2px 2px;
    /* max-height: 3.4rem; */
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.CommonCell .community_image {
    height: 35px;
    border-radius: 11px;
    border: solid 2px #F9CD16;
    margin-right: 15px;
}
