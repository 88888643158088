/* CSS */

div.NiigataStoreDetail {
  padding-bottom: 50px;
  /*border: 1px dotted #ccc;*/
}

.NiigataStoreDetail div.detail {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 40px;
  margin-bottom: 55px;
  text-align: left;
  white-space: pre-wrap;
  border-top: solid #D8D8D8 1px;
  border-bottom: solid #D8D8D8 1px;
}

.NiigataStoreDetail .detail .title {
  margin-top: 0;
  color: #4A4A4A;
  font-size: 1.2rem;
  font-weight: bold;
}

.NiigataStoreDetail .detail .text {
  padding: 0 0.5rem;
  color: #9B9B9B;
}

.NiigataStoreDetail div.border-bottom {
  border-bottom: solid #D8D8D8 1px;
}

.NiigataStoreDetail .help p {
  font-weight: 400;
  color: #9B9B9B;
  padding-top: 8px;
}

.NiigataStoreDetail div.button {
  text-align: left;
  width: 100%;
  max-width: 768px;
  /* position: fixed; */ 
  bottom: 0;
  height: 80px;
  background-color: #FFFFFF;
  border-top: solid #D8D8D8 1px;
  padding-top: 16px;
}

.NiigataStoreDetail .button p.desc {
  font-size: 0.6rem;
  color: #F5A623;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NiigataStoreDetail .button p.price {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.NiigataStoreDetail .button input {
  background-color: #F9CD16;
  color: #ffffff;
  border-radius: 6px;
  padding: 4px 16px;
  float: right;
  width: 100%;
  height: 50px;
}

.NiigataStoreDetail .button input.disable {
  background-color: #9B9B9B;
}

.NiigataStoreDetail div.date {
  padding-top: 16px;
  padding-bottom: 32px;
}

.NiigataStoreDetail .date .react-calendar {
  width: 100%;
  padding: 0;
  border: 0;
}

.NiigataStoreDetail .date .react-calendar .react-calendar__tile {
  border-radius: 5px;
  background-color: #fdeacc;
}

.NiigataStoreDetail .date .react-calendar .react-calendar__tile:disabled {
  background-color: #ffffff;
}

.NiigataStoreDetail .date .react-calendar .react-calendar__tile--active {
  background-color: #F5A623;

}

.NiigataStoreDetail .react-calendar button:disabled {
  color: #757575;
}

.NiigataStoreDetail .react-calendar button:disabled.react-calendar__month-view__days__day--neighboringMonth {
  color: #eeeeee;
}

.NiigataStoreDetail .map {
  height: 230px;
  width: 100%;
  margin-top: 16px;
  border-radius: 8px;
}

.NiigataStoreDetail .meta-box {
  text-align: left;
}

.NiigataStoreDetail .meta-box .title {
  margin-top: 18px;
  margin-bottom: 12px;
  color: #4A4A4A;
  font-size: 1.2rem;
  font-weight: bold;
}

.NiigataStoreDetail .cell {
  text-align: left;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  padding: 8px;
}

.NiigataStoreDetail .selected-user-image-list {
  text-align: left;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  height: 100px;
  margin-bottom: 16px;
}

.NiigataStoreDetail div.user-box {
  display: inline-block;
  margin: 0 8px 0 0px;
  height: 100px;
  width: 60px;
}

.NiigataStoreDetail .user-box img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #cccccc;
}

.NiigataStoreDetail .user-box p {
  font-size: 0.6px;
  text-align: center;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NiigataStoreDetail .form-box {
  text-align: left;
  background-color: #EEEEEE;
  padding: 0;
}

.NiigataStoreDetail .form-box .title {
  margin: 16px 0 6px 0;
  padding: 0 15px;
  font-size: 0.8rem;
  color: gray;
  border-bottom: 1px #EEEEEE solid;
}

.NiigataStoreDetail .input-wrapper {
  background-color: #ffffff;
  border-bottom: 1px #EEEEEE solid;
}

.NiigataStoreDetail input::placeholder, .NiigataStoreDetail textarea::placeholder {
  color: #F9CD16;
}

.NiigataStoreDetail .btn-box {
  text-align: center;
  background-color: #EEEEEE;
  padding: 64px 0;
}

.NiigataStoreDetail .btn-box .button {
  background-color: #F9CD16;
  color: #ffffff;
  font-weight: bold;
  border-radius: 6px;
  padding: 16px 0;
  margin: 0 auto;
  width: 80vw;
}

.NiigataStoreDetail .form-input-text-with-label {
  color: #F9CD16;
}

.NiigataStoreDetail div.course-image {
  width: 100%;
  height: 20vw;
  max-height: 140px;
}

.NiigataStoreDetail .course-image img {
  width: 100%;
  height: 20vw;
  max-height: 140px;
  object-fit: cover;
}

.NiigataStoreDetail .course-image p {
  position: absolute;
  bottom: 0;
  width: calc(100% - 30px);
  margin: 0;
  padding: 3px 6px;
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
  background-color: rgba(249, 205, 22, 0.8);
}

.NiigataStoreDetail .icon {
  height: 1rem;
  width: 16px;
  object-fit: contain;
  margin-right: 20px;
}

.NiigataStoreDetail .form-box .form-input-text.large {
  height: 20vh;
}
.NiigataStoreDetail input.number {
  background-color: white;
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 0 3px;
}
.NiigataStoreDetail input.number.active {
  background-color: #F9CD16;
  border: none;
  color: #ffffff;
}

.NiigataStoreDetail select.number_of_people {
  color: #F9CD16;
  margin-top: 0;
  width: 100%;
  text-align: right;
}

.NiigataStoreDetail select.number_of_people option {
  text-align: right;
}

.NiigataStoreDetail input.signup-input {
  width: 100%;
  margin-top: 0;
}

.NiigataStoreDetail select.signup-input {
  width: 100%;
  margin-top: 0;
}

.NiigataStoreDetail .CommonSegmentButton {
  margin-bottom: 20px;
}

.NiigataStoreDetail .list .title {
  text-align: left;
  padding: 16px 0 8px 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #737373;
  margin: 8px 0;
}

.NiigataStoreDetail .list .title img {
  margin: 0 16px 0 0;
  height: 18px;
}

.NiigataStoreDetail .community-list {
  text-align: left;
}

.NiigataStoreDetail .community-list .community-image-list {
  height: 50px;
  width: 50px;
  border-radius: 11px;
  border: solid 2px #F9CD16;
  margin: 0 2px;
}

/* 確認ダイアログのスタイル */
.modal-dialog .modal-body {
  text-align: left;
  padding: 0 13%;
}