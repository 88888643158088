/* CSS */

.CommonCourseCell {

}

.CommonCourseCell .box {
    display: table;
    width: 100%;
    border: 1px solid #D8D8D8;
    margin: 5px 0;
    padding: 1rem;
    text-align: left;
    background-color: white;
}

.CommonCourseCell .box.active {
    border: 1px solid #F9CD16;
    background-color: #FFF9F2;
}

.CommonCourseCell img {
    display: table-cell;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.CommonCourseCell img.small {
    width: 36px;
    height: 36px;
}

.CommonCourseCell div.course-detail {
    display: table-cell;
    vertical-align: middle;
    padding: 0 6px;
    width: 100%;
}

.CommonCourseCell .course-detail p.title {
    font-size: 1.0rem;
    font-weight: 600;
    color: #000000;
    margin: 6px 0;
    white-space: pre-wrap;
    border: none;
}

.CommonCourseCell .course-detail p.desc {
    font-size: 1.2rem;
    font-weight: 400;
    color: #F5A623;
    padding: 0;
    margin: 0 0 6px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}

.CommonCourseCell .course-detail p.price {
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
    display: block;
}

.CommonCourseCell .course-detail p.price_origin {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    display: block;
    text-decoration: line-through;
    color: #444444;
    opacity: 0.5;
}

.CommonCourseCell .course-detail p.option {
    font-size: 0.8rem;
    color: #F9CD16;
    display: inline-block;
    margin: 0 16px 0 0;
}

.CommonCourseCell .option img {
    height: 16px;
    width: auto;
    margin-top: 1px;
    display: inline-block;
    vertical-align: top;
}

.CommonCourseCell .label {
    background-color: #F9CD16;
    color: white;
    font-size: 12px;
    height: 20px;
    display: inline-block;
    border-radius: 10px;
    padding: 2px 8px;
    margin-bottom: 0.2rem;
    text-align: center;
}

.CommonCourseCell .terms {
    color: #D34C44;
    font-size: 1.0rem;
    font-weight: 600;
    margin: 0;
    white-space: pre-wrap;
}

.CommonCourseCell span.tag {
    background-color: #F9CD16;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.6rem;
    border-radius: 6px;
    margin: 2px;
    padding: 2px 4px;
}