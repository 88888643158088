/* CSS */

.CommonUserInfo {
    display: flex;
    margin: 1rem 0;
    color: #4A4A4A;
}

.CommonUserInfo .user-info-cell-right {
    margin-left: 1rem;
    max-width: 80%;
}

.CommonUserInfo .user-info-name {
    text-align: left;
}

.CommonUserInfo .user-info-light-description {
    text-align: left;
}

.CommonUserInfo .user-info-image {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
    border-radius: 1.5rem;
}

.CommonUserInfo .tail-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}