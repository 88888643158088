:root {
    --tb-font-size: 1rem;
}

.TalkBoard {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
}

.TalkBoard .board-margin {
    flex: 1;
    width: 100%;
}

.TalkBoard .message-list {
    width: 100%;
    display: flex;
    overflow: scroll;
    flex-direction: column-reverse;
    max-height: calc(100vh - 160px - 44px);
}

.TalkBoard .message-wrapper {
}

.TalkBoard .message {
    width: 100%;
    display: flex;
    padding: 10px;
}

.TalkBoard .message-left .user-icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 20px;
}

.TalkBoard .message-right {
    margin-left: 9px;
}

.TalkBoard .message-right .message-top{
    display: flex;
}

.TalkBoard .message-right .message-top .user-name {
    font-size: var(--tb-font-size);
    font-weight: bold;
}

.TalkBoard .message-right .message-top .date {
    color: gray;
    margin-left: 5px;
    margin-top: 4px;
    font-size: 0.8rem;
    bottom: 0;
}

.TalkBoard .message-right .comment {
    margin-top: 2px;
    text-align: left;
    font-size: var(--tb-font-size);
    word-break: break-all;
    white-space: pre-wrap;
}

.TalkBoard .message-image-list {
    height: 65px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.TalkBoard .message-image {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 7px;
}

.TalkBoard .message-image:last-child {
    margin-right: 0;
}

.TalkBoard .input-form {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 160px;
}

.TalkBoard .input-textarea {
    border: none;
    border-top: 0.5px solid #D8D8D8;
    width: 100%;
    height: 100px;
    padding: 10px 10px 0 10px;
    font-size: var(--tb-font-size);
    resize: none;
    -webkit-appearance: none; 
}

.TalkBoard .input-textarea::placeholder{
    color: #bbb;
}

.TalkBoard .input-footer {
    height: 60px;
}

.TalkBoard .image-label {
    float: right;
    margin-top: 14px;
    margin-right: 13px;
}

.TalkBoard .image-icon {
    color:gray;
    font-size: 23px;
}

.TalkBoard .submit-button {
    background-color: transparent;
    border-radius: 5px;
    font-size: var(--tb-font-size);
    font-weight: 500;
    color: gray;
    padding: 0;
    height: 30px;
    width: 60px;
    line-height: 27px;
    border: 1px solid #dee2e6;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
}

.TalkBoard .image-preview {
    width: 100%;
    display: flex;
    padding: 0 10px;
}

.TalkBoard .image-item {
    position: relative;
}

.TalkBoard .image-item .image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 15px;
}

.TalkBoard .close-button {
    position: absolute;
    right: 5px;
    top: -8px;
}

.TalkBoard .close-button-icon {
    background-color: white;
    color:gray;
    font-size: 25px;
    height: 23px;
    width: 23px;
    border-radius: 11.5px;
}

.TalkBoard .message-section {
    text-align: left;
    padding: 10px 0 0 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-bottom: 1px solid #dee2e6;
}