/* CSS */

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-box {
    position: absolute;
    width: 95vw;
    max-width: 600px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 10px;
}

/* CSS */

.Modal header {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #F0F0F0;
    background-color: #FFFFFF;
    border-radius: 10px 10px 0 0;
}

.Modal header div.left-button {
    text-align: left;
    display: flex;
    align-items: center;
    height: 44px;
    font-size: 0.9rem;
    font-weight: bold;
}

.Modal header .left-button img {
    height: auto;
    width: 34px;
    display: table-cell;
    vertical-align: middle;
}

.Modal header .left-button p {
    color: #F5A623;
    margin: 4px;
    padding-top: 8px;
    width: auto;
    height: 32px;
}

.Modal header .title {
    height: 44px;
    width: 100%;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.Modal header div.right-button  {
    text-align: right;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0 16px 0 0;
}

.Modal header .right-button img {
    margin: 4px;
    width: auto;
    height: 32px;
}

.Modal header .right-button p {
    color: #F5A623;
    margin: 4px;
    padding-top: 8px;
    width: auto;
    height: 32px;
}

.Modal  div.content {
    margin-top: 44px;
    max-height: 60vh;
    overflow-y: scroll;
}