/* CSS */

div.EventDetail {
    text-align: left;
    white-space: pre-wrap;
}

.EventDetail {
    position: relative;
}

.EventDetail .small-text {
    font-size: 0.9rem;
    color: #6D7278;
}

.EventDetail .main-image {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.EventDetail .main-image-label {
    height: 30px;
    color: white;
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    background-color: #9B9B9B;
}

.EventDetail .main-image > img {
    height: 200px;
    object-fit: cover;
}

.EventDetail .border {
    margin-top: 6px;
    height: 1px;
    border-bottom: solid #D8D8D8 1px;
}

.EventDetail img.icon, .EventCreate .detail-box img.icon {
    height: 1rem;
    width: 16px;
    object-fit: contain;
    margin-right: 8px;
}

.EventDetail .title-box {
    padding: 15px;
}

.EventDetail .title-box .date {
    color: #D34C44;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
}
.EventDetail .title-box .title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
}
.EventDetail .title-box .spot {
    font-weight: 300;
    margin-bottom: 0.2rem;
}

.EventDetail .dummy {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 0.9rem;
    margin: 10px auto;
    width: 90vw;
    background-color: #E4E5E4;
}

.EventDetail .event-picture-swiper-wrapper {
    margin-top: 5px;
    overflow: hidden;
}

.EventDetail .event-picture {
    margin: 0 0 0 15px;
}

.EventDetail .event-picture .image {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.EventDetail .event-picture .title {
    margin-top: 8px;
    font-size: 0.8rem;
    font-weight: 600;
}

.EventDetail .event-picture .comment {
    /* margin-top: 8px; */
    font-size: 0.8rem;
    color: gray;
    /* font-weight: 600; */
}

.EventDetail .event-mvp-user-wrapper {
    width: 100%;
}

.EventDetail .event-mvp-user {
    display: flex;
    margin: 10px 0;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.EventDetail .event-mvp-user > .image {
    height: 43px;
    border-radius: 25px;
}

.EventDetail .event-mvp-user > .name {
    display: flex;
    align-items: flex-end;
}

.EventDetail .event-mvp-user > .name > .content {
    margin-left: 10px;
    padding: 0;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: #EBBA4C;
}

.EventDetail .event-mvp-user > .name > .suffix {
    font-size: 1rem;
    line-height: 1rem;
    color: #6D7278;
    margin-left: 10px;
}

.EventDetail .most-delicious {
    margin: 10px 0;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: #EBBA4C;
    width: 100%;
    text-align: center;
}

.EventDetail .store-reply > .store-info {
    display: flex;
}

.EventDetail .store-reply > .store-info > .image {
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.EventDetail .store-reply > .store-info > .right-area {
    margin-left: 10px;
    font-size: 0.9rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
}

.EventDetail .store-reply > .store-info > .right-area > .content {
    padding: 0;
}

.EventDetail .store-reply > .store-info > .right-area .date {
    color: #9B9B9B;
}

.EventDetail .store-reply > .store-comment {
    padding: 10px 0;
}

.EventDetail {
    padding-bottom: 60px;
}

.EventDetail .footer {
    border-top: 0.5px solid #dee2e6;
    padding: 10px;
    height: 60px;
    width: 100vw;
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
}

.EventDetail .open-store-button {
    height: 40px;
    line-height: 40px;
    color: white;
    background-color: #F7C515;
    text-align: center;
    width: 80%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 5px;
}

.EventDetail .event-announce-desc {
    font-size: 0.9rem;
    word-break: break-all;
    white-space: pre-wrap;
}