div.Signup {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 88px;
}

.Signup .logo {
    margin-top: 64px;
    margin-bottom: 64px;
    width: 90%;
    height: auto;
}

.Signup .footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    max-width: 768px;
    height: 64px;
    border-top: 2px solid #E4E4E4;
    background-color: #FFFFFF;
    padding: 16px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #F9CD16;
}

.Signup .consent {
    margin: 32px 0;
}

.Signup .consent input {
    margin: 0 16px;
}

.Signup .consent input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    -webkit-appearance: checkbox;
}

.Signup img.or {
    margin: 32px auto 16px auto;
    width: 90%;
    max-width: 500px;
    height: auto;
}