/* CSS */


.CommonIconTitle {
    display: flex;
    margin-top: 0;
    padding: 6px 0;
    color: #66656B;
    font-size: 1rem;
    font-weight: 300;
}

.CommonIconTitle img.icon {
    display: inline-block;
    margin-top: 4px;
    height: 1.2rem;
    width: auto;
    object-fit: contain;
    margin-right: 16px;
}

.CommonIconTitle .btn-map {
    display: inline-block;
    margin: -4px 8px 2px 8px;
    width: 90px;
    height: 33px;
    border-radius: 22px;
    font-size: 0.8rem;
    font-weight: 600;
}

.CommonIconTitle.underline {
    text-decoration: underline;
}

.CommonIconTitle.tel {
    text-decoration: underline;
}