/* CSS */

.CommonSegmentButton {
    height: 44px;
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #D8D8D8;
}

.CommonSegmentButton .btn-group {
    width: 100%;
    height: 44px;
    border-bottom: solid 1px #D8D8D8;
}

.CommonSegmentButton .btn-secondary {
    margin: 0;
    padding: 10px;
    height: 44px;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    color: #9B9B9B;
    background-color: #ffffff;
    border-bottom: solid 1px #D8D8D8;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    background-color: #ffffff;
    color: #F5A623;
    border-bottom: solid 2px #F5A623;
}