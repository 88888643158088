/* CSS */

.ProductDetail {
    position: relative;
}

.header {
    position: absolute;
    width: 100%;
    height: 3rem;
    /* background-color: antiquewhite; */
    top: 0;
    left: 0;
    z-index: 1;
}

.trash-icon {
    color: red;
    float: right;
    font-size: 1.8rem;
    padding: 1rem;
}

.main-image-list-wrapper {
    position: relative;
    width: 100%;
}

.main-image-item-image {
    height: 90vh;
    max-height: 360px;
    object-fit: cover;
}

.info-list-wrapper {
    width: 100vw;
}

.product-info-list {
    padding: 0.5rem 0;
}

.product-info-item {
    font-size: 1rem;
    display: flex;
    padding: 0.5rem 1.0rem;
    justify-items: center;
}

.product-info-item-icon {
    height: 1.0rem;
    width: 2.0rem;
    object-fit: contain;
}

.product-info-item-description {
    margin-left: 0.3rem;
    line-height: 1.0rem;
}

.content-cell {
    padding: 0.5rem 1.0rem;
}

.user-info {
}

.border {
    margin: 0 auto;
    border: 0.5px solid #dee2e6 !important;
}

.section {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
    color: #4A4A4A;
}

ul.control-dots{
    padding: 0;
}

.tail-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-area {
    text-align: left;
    font-size: 0.9rem;
    margin: 0.4rem 0;
    white-space: pre-line;
}

.bottom-bar {
    position: fixed;
    height: 4.5rem;
    width: 100%;
    max-width: 768px;
    background-color: white;
    bottom: 0;
    border-top: solid 1px #dee2e6;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #4A4A4A;
    font-size: 1rem;
}

.bottom-bar-info {
    position: absolute;
    left: 13vw;
}

.bottom-bar-button {
    position: absolute;
    background-color: #F9CD16;
    color: white;
    padding: 0.7rem 1.5rem;
    border-radius: 0.5rem;
    right: 4vw;
}

.margin-for-bottom-bar {
    height: 4.5rem;
    width: 100%;
}

.main-image-bottom-wrapper {
    /* height: 20vh; */
    padding: 20vh 0 0.5rem 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.main-title {
    color: white;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: left;
    padding: 1rem;
}

.main-image-page-control {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1vh;
}

.page-control-item {
    flex: 1;
    margin: 1vw;
    background-color: rgba(153, 153, 153, 0.438);
    height: 0.13rem;
    max-width: 17vw;
}

.active.page-control-item {
    background-color: white;
}

.more-review-cell {
    display: flex;
    font-size: 0.9rem;
    color: #F9CD16;
    padding: 0.6rem 1rem ;
}

.ProductDetail .CommonStars {
    position: relative;
}

.ProductDetail .star-ratings {
    position: absolute!important;
    top: -0.7vw;
    left: 1vw;
    width: 60vw;
    text-align: left;
}

.ProductDetail .stars-list-wrapper {
    margin-left: 1vw;
    display: flex;
}