/* CSS */

.CommunitySetting.row {
    padding-bottom: 2rem;
}

.CommunitySetting .community-title {
    margin: 1rem 0 1rem 0;
    font-size: 1.0rem;
    font-weight: bold;
    text-align: left;
    color: #4A4A4A;
}

.CommunitySetting .community-image {
    padding: 0;
    text-align: left;
}

.CommunitySetting div.input-tags {
    margin: 0;
    padding: 10px;
    width: 100%;
    font-size: 20px;
    border: 0px;
    border-bottom: 2px solid #E4E4E4;

}

.CommunitySetting .image {
    height: 12vw;
    width: 12vw;
    max-height: 120px;
    max-width: 120px;
    object-fit: cover;
    border-radius: 1rem;
}

.CommunitySetting .invite {
    margin: 1.5rem 0;
}

.CommunitySetting .invite-image {
    height: 16vw;
    width: 16vw;
    max-height: 140px;
    max-width: 140px;
    object-fit: cover;
    border-radius: 50%;
}

.CommunitySetting .community-name {
    margin: 0;
    font-size: 1.1rem;
    text-align: left;
    color: #4A4A4A;
}

.CommunitySetting .community-num {
    margin: 0;
    font-size: 0.9rem;
    text-align: left;
    color: #9B9B9B;
}

.CommunitySetting div.community-desc {
    padding-top: 1rem;
    padding: 0;
}

.CommunitySetting .community-desc p {
    margin: 1rem 0 0.5rem 0;
    font-size: 0.9rem;
    text-align: left;
    color: #9B9B9B;
    height: auto;
}

.CommunitySetting label {
    margin: 0.2rem 0 0 0;
    padding: 1rem 0 0 0;
    width: 100%;
    text-align: left;
    font-weight: 600;
    color: #9B9B9B;
    font-size: 0.8rem;
}

.CommunitySetting input.signup-input {
    margin: 0;
    width: 100%;
}

.CommunitySetting p.community-link {
    margin: 0.2rem 0 0.5rem 0;
    font-size: 0.8rem;
    text-align: left;
    color: #9B9B9B;
    height: auto;
    width: 90%;
}

.CommunitySetting .qr {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CommunitySetting ul {
    padding: 0;
    width: 100%;
    list-style-type: none;
}

.CommunitySetting li {
    text-align: left;
    padding: 16px 32px;
    border-bottom: 1px solid #F0F0F0;
    font-weight: 600;
}