.CommonComments {
  text-align: left;
  margin: 7px 0;
}

/* ユーザーアイコンリスト */
.CommonComments .user-image-list {
  display: flex;
}

.CommonComments .user-image-list > img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow-x: hidden;
  white-space: nowrap;
  margin: 5px 7px 0 0;
  border: 2px solid rgba(249, 205, 22, 0);
  object-fit: cover;
}

.CommonComments .user-image-list > img.selected {
  border: 2px solid rgba(249, 205, 22, 1.0);
}

.CommonComments .user-image-list:last-child > img {
  margin-right: 0;
}

/* コメントリスト */
.CommonComments .user-comment-list {
  text-align: left;
}

.CommonComments .user-comment-list > .user-comment-cell {
  padding: 5px 0 0;
}

.CommonComments .user-comment-list > .user-comment-cell > .user-name {
  padding: 5px 0;
}

.CommonComments .user-comment-list > .user-comment-cell > .user-comment {
  padding: 10px 15px;
  border-radius: 7px;
  background-color: #E4E5E4;
}