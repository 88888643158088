/* CSS */

.CommunityAccount .user-image {
    height: 20vw;
    width: 20vw;
    max-height: 120px;
    max-width: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 64px;
    margin-bottom: 16px;
    border: 1px solid #cccccc;
}

.CommunityAccount .user-name {
    font-weight: 600;
}

.CommunityAccount div.community-tag {
    margin: 16px 0 0.5rem 0;
    text-align: left;
}

.CommunityAccount p.tag {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    border: solid #F9CD16 1px;
    border-radius: 1rem;
    color: #FFFFFF;
    margin-right: 0.4rem;
    margin-bottom: 0.5rem;
    background-color: #F9CD16;
}

.CommunityAccount .community-desc p {
    margin: 24px 0 0.5rem 0;
    font-size: 0.9rem;
    text-align: left;
    height: auto;
}

.CommunityAccount div.community-invite {
    text-align: left;
    margin: 16px 0 0.5rem 0;
}

.CommunityAccount p.title {
    font-size: 0.9rem;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.CommunityAccount .community-invite img.user-image {
    margin: 0;
    width: 40px;
    height: 40px;
}