/* CSS */

header {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    position: -webkit-sticky; /* safari対応 */
    position: sticky;
    z-index: 99;
    top: 0px;
    text-align: center;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #F0F0F0;
    background-color: #FFFFFF;
}

header div.left-button {
    text-align: left;
    display: flex;
    align-items: center;
    height: 44px;
}

header .left-button img {
    height: auto;
    width: 34px;
    display: table-cell;
    vertical-align: middle;
}

header .left-button p {
    color: #F5A623;
    margin: 4px;
    padding-top: 4px;
    padding-left: 0;
    width: auto;
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

header .title {
    height: 44px;
    width: 100%;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

header div.right-button  {
    text-align: right;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0 16px 0 0;
}

header .right-button img {
    margin: 4px;
    width: auto;
    height: 32px;
}

header .right-button p {
    color: #F5A623;
    margin: 4px;
    padding-top: 8px;
    width: auto;
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}