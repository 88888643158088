/* CSS */

.BenefitCreate {

}

.BenefitCreate .form-box {
    text-align: left;
    background-color: #EEEEEE;
    padding: 0;
}

.BenefitCreate .form-box .title {
    margin: 16px 0 6px 0;
    padding: 0 15px;
    font-size: 0.8rem;
    color: gray;
    border-bottom: 1px #EEEEEE solid;
}

.BenefitCreate .input-wrapper {
    overflow: visible;
    background-color: #ffffff;
    border-bottom: 1px #EEEEEE solid;

}

.BenefitCreate input::placeholder, .BenefitCreate textarea::placeholder {
    color: #F9CD16;
}

.BenefitCreate .form-input-text-with-label {
    color: #F9CD16;
}

.BenefitCreate .form-input-label {
    color: #737373;
}

.BenefitCreate .input-wrapper span {
    padding: 0.5rem 1rem;
}

.BenefitCreate .form-input-text.price {
    width: 90%;
    text-align: right;
    color: #737373;
}

.BenefitCreate .form-box .form-input-text.large {
    height: 20vh;
}

.BenefitCreate input.btn {
    margin: 32px auto 16px auto;
    width: 100%;
    background-color: #F1C400;
    color: #ffffff;
    font-weight: 600;
}