/* CSS */

div.Guide {
    margin-top: 16px;
    margin-bottom: 16px;
}

.Guide div.guide-container {
    background-color: #f0f0f0;
    margin-top: 5px;
    padding: 5px 15px;
    border-radius: 10px;
    text-align: center;
}

.Guide .guide-container h1 {
    font-size: 1.2rem;
    color: #ffc107;
    text-align: left;
}

.Guide .guide-container img {
    width: auto;
    height: 60px;
    top: 35px;
    margin: 0px auto;
    padding-top: 5px;
}

.Guide .guide-container p {
    color: #000000;
    font-size: 1rem;
    margin-top: 18px;
    margin-bottom: 0px;
}
