/* CSS */

.Stripe.row {
    padding-bottom: 32px;
}

.Stripe label {
    margin: 16px 0 0 0;
    width: 90%;
    text-align: left;
    font-weight: 600;
    color: #9B9B9B;
    font-size: 0.8rem;
}

.Stripe input.signup-input {
    margin-top: 0;
}


.Stripe label.user-image {
    text-align: center;
}

.Stripe .user-image img {
    margin: 0;
    width: 100%;
    height: 50vw;
    border-radius: 16px;
    object-fit: contain;
}


.Stripe .user-image input {
    display: none;
}