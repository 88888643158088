/* CSS */

.Purchase {

}

.Purchase .cell {
    padding: 1rem 0;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #F0F0F0;
}

.Purchase .date {
    text-align: center;
    padding: 16px;
    /*border-bottom: 1px solid #F0F0F0;*/
}

.Purchase .detail {
    text-align: left;
    padding: 16px 0;
    border-bottom: 1px solid #F0F0F0;
    /*border-bottom: 1px solid #F0F0F0;*/
}

.Purchase .detail h1 {
    text-align: left;
    color: #F5A623;
    font-size: 1.3rem;
    /*border-bottom: 1px solid #F0F0F0;*/
}

.Purchase .detail p {
    text-align: left;
    color: #9B9B9B;
    font-size: 0.9rem;
    /*border-bottom: 1px solid #F0F0F0;*/
    white-space: pre-line;
}

.Purchase .date p {
    color: #9B9B9B;
    padding: 16px;
    text-align: left;
}

.Purchase .date ul {
    list-style: none;
    color: #9B9B9B;
    padding: 16px 16px 0 16px;
    margin: 0;
    text-align: left;
}

.Purchase .date li {
    text-decoration: none;
}

.Purchase .desc {
    font-size: 0.8rem;
    color: #9B9B9B;
    padding: 16px;
    border-bottom: 1px solid #F0F0F0;
}

.Purchase .desc h2 {
    color: #4A4A4A;
    padding: 16px;
    text-align: left;
}

.Purchase p.title {
    font-size: 1.0rem;
    font-weight: bold;
    text-align: left;
    color: #9B9B9B;
    padding: 16px;
    text-align: left;
}

.Purchase p.notes {
    color: #9B9B9B;
    padding: 0 16px;
    text-align: left;
    white-space: pre-line;
}

.Purchase .member {
    font-size: 0.8rem;
    color: #9B9B9B;
    padding: 16px;
    border-bottom: 1px solid #F0F0F0;
}

.Purchase .member p {
    color: #9B9B9B;
    padding: 16px;
    text-align: left;
}

.Purchase .payment {
    color: #9B9B9B;
    padding: 16px;
    border-bottom: 1px solid #F0F0F0;
}

.Purchase .payment .cell p {
    margin: 0;
    font-size: 1.1rem;
    color: #000000;
    text-align: left;
}

.Purchase .payment .cell .title p {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari用 */
    transform: translateY(-50%);
}

.Purchase .payment .cell .arrow p {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari用 */
    transform: translateY(-50%);
}

.Purchase .payment .right p {
    text-align: right;
}

div.DayPicker {
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

div.DayPicker div {
    margin: 0 auto;
}

.Purchase .member-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    object-fit: cover;
    margin: 6px;
    vertical-align: top;
}

.Purchase .detail-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.Purchase .member-box {
    display: inline-block;
    max-width: 80vw;
}

.Purchase p.member-name {
    margin: 6px 0;
    color: #000000;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Purchase .date .react-calendar {
    width: 100%;
    padding: 0;
    border: 0;
}

.Purchase .date .react-calendar .react-calendar__tile {
    border-radius: 5px;
    background-color: #fdeacc;
}

.Purchase .date .react-calendar .react-calendar__tile:disabled {
    background-color: #ffffff;
}

.Purchase .date .react-calendar .react-calendar__tile--active {
    background-color: #F5A623;

}

.StripeCheckout {
    margin: 16px;
}

.Purchase .payment .address p {
    font-size: 1rem;
}

.Purchase .space {
    height: 20px;
    border-bottom: 1px solid #F0F0F0;
}
.Purchase input.signup-input {
    margin: 0 auto;
    width: 100%;
    margin-top: 0;
}

.Purchase .address-inputs {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
