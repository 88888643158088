/* CSS */

.Balance .balance {
    padding: 16px;
}

.Balance h1 {
    text-align: left;
    font-size: 1.3rem;
    font-weight: 600;
    padding-top: 50px;
}

.Balance h2 {
    text-align: left;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

.Balance p {
    text-align: left;
    font-size: 1rem;
    padding-top: 1rem;
}

.Balance .balance input {
    text-align: right;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1rem 0 0 0;
    padding: 1rem;
    border: 1px solid #9B9B9B;
    width: 100%;
}

.Balance .bank {
    text-align: left;
    padding: 1rem;
    border-bottom: 1px solid #9B9B9B;
}

.Balance .add p {
    text-align: right;
    margin: 0;
    padding: 0;
}

.Balance form {
    text-align: left;
}

.Balance button.btn-secondary {
    width: 100%;
    margin: 1rem auto;
}

.Balance input.signup-input {
    margin: 0 auto;
    width: 100%;
    margin-top: 0;
}

.Balance select.signup-input {
    margin: 0 auto;
    width: 100%;
    margin-top: 0;
}

.Balance label {
    text-align: left;
    padding: 1rem 0 0 0;
}