/* CSS */

.NotificationList {

}

.NotificationList .cell div.box {
    padding: 0.2rem 1rem;
}

.NotificationList .cell p.text {
    margin: 0px 6px;
}

.NotificationList div.user-detail {
    padding: 0 0 0 16px;
}

.NotificationList p.no-data {
    text-align: center;
    margin: 32px auto;
}