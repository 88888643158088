html,
body {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    background: #ffffff;
}

a {
    color: #F9CD16;
}

#root {
    text-align: center;
}

.row {
    margin: 0;
}

.App {
    text-align: center;
    margin: 0 auto;
    padding: 0;
}

div.container {
    max-width: 768px;
    background-color: white;
    padding: 0;
}

.app-logo {
    width: 80%;
    pointer-events: none;
    border-radius: 50%;
}

.app-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.app-link {
    color: #61dafb;
}

.btn {
    margin-top: 20px;
    width: 90%;
    height: 60px;
}

button.btn-primary {
    background-color: #F9CD16;
    border-color: #F9CD16;
    font-size: 1.5rem;
    font-weight: 600;
}

button.btn-primary:hover, button.btn-primary:focus, button.btn-primary:active, button.btn-primary:visited {
    background-color: #ffdd75;
    border-color: #ffdd75;
}

button.btn-secondary {
    background-color: #E4E4E4;
    border: solid 1px #E4E4E4;
    font-size: 1.2rem;
    font-weight: 300;
    color: #000000;
    width: 90%;
}

button.btn-secondary:hover, button.btn-secondary:focus, button.btn-secondary:active, button.btn-secondary:visited {
    background-color: #BCBCBC;
    border-color: #BCBCBC;
    color: #000000;
}

button.btn-facebook {
    position: relative;
    background-color: #3B5998;
    border: solid 1px #3B5998;
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFFFFF;
    width: 90%;
}

button.btn-facebook:hover, button.btn-facebook:focus, button.btn-facebook:active, button.btn-facebook:visited {
    background-color: #2D4577;
}

button.btn-google {
    position: relative;
    background-color: #4285F4;
    border: solid 1px #4285F4;
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFFFFF;
    width: 90%;
}

button.btn-google:hover, button.btn-google:focus, button.btn-google:active, button.btn-google:visited {
    background-color: #2D4577;
}

button.btn-twitter {
    position: relative;
    background-color: #38A1F3;
    border: solid 1px #38A1F3;
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFFFFF;
    width: 90%;
}

button.btn-twitter:hover, button.btn-twitter:focus, button.btn-twitter:active, button.btn-twitter:visited {
    background-color: #2D4577;
}

button.btn-line {
    position: relative;
    background-color: #00C300;
    border: solid 1px #00C300;
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFFFFF;
    width: 90%;
}

button.btn-line:hover {
    background-color: #00E000;
    color: #FFFFFF;
}

button.btn-line:focus, button.btn-line:active, button.btn-line:visited {
    background-color: #00B300;
    color: #FFFFFF;
}

button.btn-congregate {
    position: relative;
    background-color: #F9CD16;
    border: solid 1px #F9CD16;
    font-size: 1.2rem;
    font-weight: 600;
    color: #FFFFFF;
    width: 90%;
}

button.btn-congregate:hover {
    background-color: #ffdb2c;
    color: #FFFFFF;
}

button.btn-congregate:focus, button.btn-congregate:active, button.btn-congregate:visited {
    background-color: #ffdb2c;
    color: #FFFFFF;
}

input.form-control {
    margin-top: 20px;
    width: 90%;
    height: 60px;
    font-size: 1.5rem;
}

.navbar {
    color: white;
    background-color: #EF6072;
    text-align: center;
}

input.signup-input {
    margin-top: 32px;
    padding: 10px;
    width: 90%;
    font-size: 20px;
    border: 0px;
    border-bottom: 2px solid #E4E4E4;
}

select.signup-input {
    margin-top: 32px;
    padding: 10px;
    width: 90%;
    height: 50px;
    font-size: 20px;
    border: 0px;
    border-bottom: 2px solid #E4E4E4;
    background-color: #FFFFFF;
}

.btn-group-toggle .btn, select, input {
    -webkit-appearance: none;
    border-radius: 0;
}

.cursor {
    opacity: 1.0;
    transition: opacity 0.1s;
    cursor: pointer;
}

.cursor:hover {
    opacity: 0.6;
}

.fade-enter {
    opacity: 0.0;
    transition: 10ms;
    z-index: 100;
}

.fade-enter-active {
    opacity: 1;
    transition: 10ms;
    z-index: 100;
}

.fade-enter-done {
    opacity: 1;
    transition: 10ms;
    z-index: 100;
}

.fade-exit {
    opacity: 1;
    transition: 200ms;
}

.fade-exit-active {
    opacity: 0;
    transition: 200ms;
}

.fade-exit-done {
    display: none;
    opacity: 0;
    z-index: 0;
    transition: 200ms;
}

.error {
    color: red;
}

/* react-datepicker */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 999;
}

.react-datepicker__input-container input {
    height: 50px;
    color: #000000;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    width: 100%;
    text-align: left;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #F0F0F0;
    background-color: white;
}
.react-datepicker__input-container input::placeholder {
    color: #333333;
}

.react-datepicker__close-icon::after {
    background-color: #E4E4E4;
}