/* CSS */

div.Home {
    width: 100%;
}

.Home Header {
    margin-bottom: 32px;
}

.Home .community {
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    text-align: left;
    padding: 0;
}

.Home .community .community-name {
    margin: 0;
    padding: 6px 12px;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    word-wrap: break-word;
}

.Home .community .community-count {
    margin: 0;
    padding: 6px 12px;
    text-align: left;
    font-size: 0.8rem;
}

.Home .community div.community-image {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 1rem 0;
}

.Home .community div.community-image:before {
    content: "";
    display: block;
    padding: 100% 0 0 0;
}

.Home .community .community-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.Home .community .community-box {
    display: inline-block;
    width: auto;
    margin: 1rem 0;
}

.Home .no_community {
    width: 60%;
    height: auto;
    margin: 32px;
}