/* CSS */

.Talk.row {
    padding-bottom: 200px;
}

.Talk form {
    margin: 0;
}

.Talk textarea {
    width: 90%;
    border: 0;
    padding: 10px;
    font-size: 1.2rem;
    border: 2px solid #E4E4E4;
    background-color: #FFFFFF;
}

.Talk .cell {
    text-align: left;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    padding: 8px;
}

.Talk .room-image {
    width: 10vw;
    height: 10vw;
    max-height: 80px;
    max-width: 80px;
    border-radius: 5vw;
    display: inline-block;
    object-fit: cover;
    margin: 6px 20px 6px 6px;
    vertical-align: top;
}

.Talk .room-box {
    display: inline-block;
}

.Talk .room-text {
    margin: 6px 0;
    color: #000000;
    word-wrap: break-word;
}

.Talk .room-user-name {
    margin: 6px 0;
    color: #8C8C8C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
}

.Talk .footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    max-width: 768px;
    height: 200px;
    border-top: 2px solid #E4E4E4;
    background-color: #FFFFFF;
    padding: 16px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #F9CD16;
}