/* CSS */

div.Regist {
    padding-bottom: 1rem;
}

.Regist .img_area {
    position:  relative;
    width: 20vw;
    height: 20vw;
    margin: 32px auto;
}

.Regist .image .add {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6vw;
    height: 6vw;
}

.Regist .logo {
    width: 20vw;
    height: 20vw;
    /*border-radius: 50%;*/
    object-fit: cover;
    border: 1px solid #cccccc;
}

.Regist .user-image {
    display: none;
}

.Regist .image label {
    width: 100%;
    text-align: center;
}

.Regist .detail label {
    margin: 0.2rem 0 0 0;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    font-weight: 600;
    color: #9B9B9B;
    font-size: 0.8rem;
}

.Regist input.signup-input {
    margin-top: 8px;
}

.Regist select.signup-input {
    margin-top: 8px;
}

.Regist p.error {
    margin: 0.2rem auto;
    padding: 1rem 0 0 0;
    width: 90%;
    text-align: left;
    color: red;
}

.Regist div.terms {
    padding: 32px 0 16px;
    text-align: center;
    vertical-align: top;
}

.Regist div.terms label {
    width: auto;
    padding: 0;
    vertical-align: top;
}

.Regist input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    -webkit-appearance: checkbox;
    margin-right: 16px;
}