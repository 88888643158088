/* CSS */

div.CommonTitle {
    padding: 12px 16px;
}

.CommonTitle .title img.icon {
    height: 1.1rem;
    width: auto;
    margin-right: 0.5rem;
}

.CommonTitle .title {
    color: #737373;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: left;
}
