/* CSS */

div.CommunityTop {
    text-align: left;
}

.CommunityTop .swiper {
    padding: 0;
    min-height: 320px;
    text-align: center;
}


.Congregate .swiper-container {
    padding-left: 5px;
}

.Congregate .cell-kenjin {
    width: auto;
    padding: 0 10px;
    margin-bottom: 10px;
}