CommonSwiperdiv.Slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 88px;
}

.Slide .logo {
    margin: 0;
    width: 100%;
    max-width: 400px;
    height: auto;
}

.Slide img {
    width: 100%;
}

.Slide p {
    color: #737373;
    font-size: 16px;
    font-weight: 300;
    white-space: pre-wrap;
    padding-bottom: 32px;
}