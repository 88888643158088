/* CSS */

.ReviewList {

}

.ReviewList .CommonUserInfo {
    width: 100%;
    padding: 0 1rem;
}

.ReviewList .review-list-wrapper {
    /* display: flex; */
    width: 100%;
}

.ReviewList .text-area {
    text-align: left;
    font-size: 0.9rem;
    margin: 0.4rem 0;
    white-space: pre-line;
    padding: 0 1rem;
}

.ReviewList .review-cell-wrapper {
    width: 100%;
}

.ReviewList .border {
    margin: 0 auto;
    border: 0.5px solid #dee2e6;
}
